import React from "react";
import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  return (
    <section className="testimonial">
    <div className="container">
    <div className="testi-heading text-center">
    <h4>Testimonials</h4>
    <h2>What People Say</h2>
    </div>
    <div className="slides">
    <Slider {...settings}>
      <div className="main">
      <div className="main2">
      <div className="media">
        <img src="images/1-2.jpg" alt=""/>
        <div className="media-body">
                    <p className="texti-p">Verma WebSolutions transformed our online presence with stunning web design and effective lead generation.</p>
            <ul className="star">
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
            </ul>
            <div className="provider">
            <h4>Ocean Babs</h4>
            <span>Head of IT</span>
            </div>
        </div>
        </div>
        </div>
      </div>
      <div className="main">
      <div className="main2">
      <div className="media">
        <img src="images/2.jpg" alt=""/>
        <div className="media-body">
                    <p className="texti-p">The landing pages designed by Verma WebSolutions have dramatically increased our conversion rates.</p>
            <ul className="star">
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
            </ul>
            <div className="provider">
            <h4>Hundi Monas</h4>
            <span>Marketing</span>
            </div>
        </div>
        </div>
        </div>
      </div>
      <div>
      <div className="main">
      <div className="main2">
      <div className="media">
        <img src="images/1-3.jpg" alt=""/>
        <div className="media-body">
                      <p className="texti-p">Verma's web development is exceptional. They created a robust and visually stunning website.</p>
            <ul className="star">
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
                <li><StarIcon className="icon1"/></li>
            </ul>
            <div className="provider">
            <h4>Adam Jones</h4>
            <span>Designer</span>
            </div>
        </div>
        </div>
        </div>
      </div>
      </div>
    </Slider>
    </div>
  </div>
  </section>
);
}