import React from 'react'
import CounterSection from './Counter'
import InstagramIcon from '@mui/icons-material/Instagram';
import { useState, useEffect } from 'react';
import Loader from './Loader2';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Example } from './Card';
import { motion } from 'framer-motion';

export default function About() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <Helmet>
        <title>About - Verma Websoluitions</title>
        <meta
          name='description'
          content="Learn more about Verma Web Solutions, your trusted IT partner for innovative web solutions. Discover our mission, values, and commitment to helping businesses thrive in the digital world."
        />
      </Helmet>
      <motion.div
        initial={{ Y: -300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ Y: -300, opacity: 0 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
        {/* <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
          </div>
        )}
      </div> */}
        <section className='about-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2 className='about-head'>About Us</h2>
                <a href=''><Link exact className="active_class color" to="/">Home</Link></a>
                <span>/ About Us</span>
              </div>
              {/* <div className='col-lg-6'>
      <a className='about-button' href='https://vermawebsolutions.com/'>Home</a>
      </div> */}
            </div>
          </div>
        </section>
        <section className='about-area'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-7 col-sm-12'>
                <div className='about-1'>
                  <img src='images/1.jpg' className='img-1' alt='' />
                  <img src='images/2-1.jpg' className='img-2' alt='' />
                  <div className='overlay'>
                    <div className='content'>
                      <h4>5+ years of experience</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-5 col-sm-12'>
                <div className='about-2'>
                  <h2>Your Partners in Digital Transformation</h2>
                  <p>Verma Websolutions offers a diverse range of services tailored to meet the digital needs of modern businesses. Specializing in UI/UX design, they create intuitive and engaging interfaces that enhance user experiences.</p>
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Our Vision and Mission
                          </button>
                        </h5>
                      </div>

                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                          At Vermawebsolutions, our vision is to empower businesses through innovative digital solutions that drive growth and success in the ever-evolving digital landscape.
                          <br /><br />
                          Our mission is to be your trusted partner in digital transformation, delivering tailored solutions that exceed expectations, foster long-term partnerships, and ensure your business thrives in a competitive world.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Our Approach
                          </button>
                        </h5>
                      </div>
                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                          We prioritize understanding your unique challenges and objectives, ensuring every solution we propose is aligned with your business goals and delivers measurable results.
                          Utilizing agile methodologies, we maintain flexibility and transparency throughout project lifecycles, ensuring swift adaptation to changes and consistently delivering value.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Why Choose Vermawebsolutions?
                          </button>
                        </h5>
                      </div>
                      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div class="card-body">
                          With years of experience in the IT industry, our team brings deep expertise across various domains and technologies to every project.
                          We are committed to delivering high-quality solutions and services that meet rigorous standards, ensuring reliability, security, and scalability.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CounterSection />
        <section className='process'>
          <div className='container'>
            <div className='process-heading'>
              <h4>PROCESS</h4>
              <h2>How we works</h2>
            </div>
            <div className='row'>
              <div className='col-lg-3 col-md-6'>
                <motion.div
                  whileHover={{ y: -20 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className='process-1'>
                  <div className='process-inner'>
                    <img src='images/selection.png' alt='' className='w-25' />
                    <span>01</span>
                    <h5>Choose a Service</h5>
                    <p>At VermaWebSolutions, we believe in a streamlined, client-focused approach to delivering top-tier IT solutions.</p>
                  </div>
                </motion.div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <motion.div
                  whileHover={{ y: -20 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className='process-1'>
                  <div className='process-inner'>
                    <img src='images/video-calling.png' alt='' className='w-25' />
                    <span>02</span>
                    <h5>Request a Meeting</h5>
                    <p>Join us for an insightful session where we delve into the intricate workings that drive Vermawebsolutions forward.</p>
                  </div>
                </motion.div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <motion.div
                  whileHover={{ y: -20 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className='process-1'>
                  <div className='process-inner'>
                    <img src='images/strategy.png' alt='' className='w-25' />
                    <span>03</span>
                    <h5>Receive Custom Plan</h5>
                    <p>Discover how Vermawebsolutions crafts bespoke solutions tailored to meet your unique business requirements.</p>
                  </div>
                </motion.div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <motion.div
                  whileHover={{ y: -20 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className='process-1'>
                  <div className='process-inner'>
                    <img src='images/chat.png' alt='' className='w-25' />
                    <span>04</span>
                    <h5>Let’s Make it Happen</h5>
                    <p>Join us for an engaging session where we unveil our collaborative approach to delivering exceptional IT solutions.</p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        <section className='about-team'>
          <div className='container'>
            <div className='team-heading'>
              <h4>EXPERT TEAM</h4>
              <h2>Meet Our Leadership</h2>
            </div>
            <div className='row'>
              <div className='col-lg-3 col-md-6'>
                <div className='team1'>
                  <img src='images/1-2 (1).jpg' alt='' className='w-100' />
                  {/* <ul>
                  <li><a href=''><img src='images/sm3.png' alt='' /></a></li>
                  <li><a href=''><InstagramIcon id="insta" /></a></li>
                  <li><a href=''><img src='images/linkedin.png' alt='' /></a></li>
                </ul> */}
                  <div className='team1-head'>
                    <h3>Bhoop Sain</h3>
                    <span>Founder & Ceo</span>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='team1'>
                  <img src='images/kapil.jpg' alt='' className='w-100' />
                  {/* <ul>
                  <li><a href=''><img src='images/sm3.png' alt='' /></a></li>
                  <li><a href=''><InstagramIcon id="insta" /></a></li>
                  <li><a href=''><img src='images/linkedin.png' alt='' /></a></li>
                </ul> */}
                  <div className='team1-head'>
                    <h3>Kapil Bishnoi</h3>
                    <span>Web Developer</span>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='team1'>
                  <img src='images/gobind4.jpg' alt='' className='w-100' />
                  {/* <ul>
                  <li><a href=''><img src='images/sm3.png' alt='' /></a></li>
                  <li><a href=''><InstagramIcon id="insta" /></a></li>
                  <li><a href=''><img src='images/linkedin.png' alt='' /></a></li>
                </ul> */}
                  <div className='team1-head'>
                    <h3>Gobind Kumar</h3>
                    <span>Web Designer</span>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='team1'>
                  <img src='images/2 (1).jpg' alt='' className='w-100' />
                  {/* <ul>
                  <li><a href=''><img src='images/sm3.png' alt='' /></a></li>
                  <li><a href=''><InstagramIcon id="insta" /></a></li>
                  <li><a href=''><img src='images/linkedin.png' alt='' /></a></li>
                </ul> */}
                  <div className='team1-head'>
                    <h3>Saroj Rani</h3>
                    <span>HR Executive</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </>
  )
}
