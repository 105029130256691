import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import TransitionsModal from './Pop';
import Loader from './Loader2';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Training() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <Helmet>
        <title>Training - Verma Websoluitions</title>
        <meta
          name='description'
          content="Empower your team with Verma Websolution's comprehensive IT training programs. Explore our training page to discover how we can enhance your team's skills and boost productivity. From software development to cybersecurity, we offer tailored courses to meet your specific needs."
        />
      </Helmet>
      <motion.div
        initial={{ Y: -300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ Y: -300, opacity: 0 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
      {/* <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
          </div>
        )}
      </div> */}
      <section className='training'>
        <div className='container'>
          <div className='row'>
            <div className='training-text'>
              <h2>Training</h2>
              <a href=''><Link exact className="active_class color" to="/">Home</Link></a>
              <span>/ Training</span>
            </div>
          </div>
        </div>
      </section>
      <section className='training-1'>
        <div className='container'>
          <h2 className='training-1-heading'>Get It Industrial Training <span>in Abohar</span></h2>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='training-1-text'>
                <p>Are you ready to revolutionize your IT skills? Join our IT courses specifically designed for industry competence at Vermawebsoluitions Institute in Abohar.</p>
                <p>Discover new horizons, explore emerging technologies, get job ready and become a tech industry trailblazer. Don't miss out on this opportunity to shape the future. Enroll now to earn high paying IT skills!</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='training-1-list'>
                <div className='media' id='bg'>
                  <img src='images/bar-graph4.png' alt='' />
                  <div className='media-body'>
                    <p>Industry-Oriented Skills & Training</p>
                  </div>
                </div>
                <div className='media' id='bg2'>
                  <img src='images/graduation.png' alt='' />
                  <div className='media-body'>
                    <p>Learn from top-notch faculty</p>
                  </div>
                </div>
                <div className='media' id='bg3'>
                  <img src='images/newspaper-folded.png' alt='' />
                  <div className='media-body'>
                    <p>Weekly Mock Interview Sessions</p>
                  </div>
                </div>
                <div className='media' id='bg4'>
                  <img src='images/social.png' alt='' />
                  <div className='media-body'>
                    <p>Certification Valid Abroad</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='our-course'>
        <div className='container'>
          <h2 className='course-heading'>Our <span>Courses</span></h2>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='course1'>
                <img src='images/group-study-in-university-librar.jpg' alt='' />
                <h3>Web Designing</h3>
                <ul className='p-0 list-unstyled'>
                  <li><CheckIcon id="check" /> Duration : 4 - 6 Months</li>
                  <li><CheckIcon id="check" /> Class Mode : Offline/Online</li>
                  <li><CheckIcon id="check" /> Language : Html5 , Css3 , jQuery and Bootstrap 5</li>
                  <li><CheckIcon id="check" /> Skill in High Demand</li>
                </ul>
                <TransitionsModal />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='course1'>
                <img src='images/young-students-thoughtfully-work.jpg' alt='' />
                <h3>Front End Development</h3>
                <ul className='p-0 list-unstyled'>
                  <li><CheckIcon id="check" /> Duration : 6 - 8 Months</li>
                  <li><CheckIcon id="check" /> Class Mode : Offline/Online</li>
                  <li><CheckIcon id="check" /> Language : Javascript , React Js , Redux and Next Js</li>
                  <li><CheckIcon id="check" /> Skill in High Demand</li>
                </ul>
                <TransitionsModal />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='course1'>
                <img src='images/technology-software-business-off.jpg' alt='' />
                <h3>Full Stack Development</h3>
                <ul className='p-0 list-unstyled'>
                  <li><CheckIcon id="check" /> Duration : 8 - 10 Months</li>
                  <li><CheckIcon id="check" /> Class Mode : Offline/Online</li>
                  <li><CheckIcon id="check" /> Language : Node Js , Express Js and Mongodb</li>
                  <li><CheckIcon id="check" /> Skill in High Demand</li>
                </ul>
                <TransitionsModal />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='course1'>
                <img src='images/clever-young.jpg' alt='' />
                <h3>Graphic Designing</h3>
                <ul className='p-0 list-unstyled'>
                  <li><CheckIcon id="check" /> Duration : 4 - 6 Months</li>
                  <li><CheckIcon id="check" /> Class Mode : Offline/Online</li>
                  <li><CheckIcon id="check" /> Tools : Canva , Figma , Adobe Psd and Corel Draw</li>
                  <li><CheckIcon id="check" /> Skill in High Demand</li>
                </ul>
                <TransitionsModal />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='course1'>
                <img src='images/group-study.jpg' alt='' />
                <h3>Content Management System</h3>
                <ul className='p-0 list-unstyled'>
                  <li><CheckIcon id="check" /> Duration : 4 - 6 Months</li>
                  <li><CheckIcon id="check" /> Class Mode : Offline/Online</li>
                  <li><CheckIcon id="check" /> Tools : Wordpress , Wix , Webflow and Shopify</li>
                  <li><CheckIcon id="check" /> Skill in High Demand</li>
                </ul>
                <TransitionsModal />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='course1'>
                <img src='images/using-programming.jpg' alt='' />
                <h3>Ui Ux Design</h3>
                <ul className='p-0 list-unstyled'>
                  <li><CheckIcon id="check" /> Duration : 4 - 6 Months</li>
                  <li><CheckIcon id="check" /> Class Mode : Offline/Online</li>
                  <li><CheckIcon id="check" /> Tools : User Research , Visual Design and Usability</li>
                  <li><CheckIcon id="check" /> Skill in High Demand</li>
                </ul>
                <TransitionsModal />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='why-it'>
        <div className='container'>
          <div className='why-it-heading'>
            <h2>Why Choose Your  <span>Career in IT ?</span></h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='it1'>
                <div className='media' id='it-bg'>
                  <img src='images/street-view.png' alt=''></img>
                  <div className='media-body'>
                    <h5>Diverse Career Options</h5>
                    <p>Limitless Opportunities & diverse career paths.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='it1' id='it2'>
                <div className='media' id='it-bg'>
                  <img src='images/menorah.png' alt=''></img>
                  <div className='media-body'>
                    <h5>In-demand</h5>
                    <p>IT professionals are in high demand.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='it1' id='it3'>
                <div className='media' id='it-bg'>
                  <img src='images/binoculars.png' alt=''></img>
                  <div className='media-body'>
                    <h5>Highest Paying Industry</h5>
                    <p>One of the highest paying Sector Globally</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='it1' id='it4'>
                <div className='media' id='it-bg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="55px" height="55px" viewBox="0 0 640 512"><path fill="#cc4a1e" d="M528 448H352V153.25c20.42-8.94 36.1-26.22 43.38-47.47l132-44.26c8.38-2.81 12.89-11.88 10.08-20.26l-10.17-30.34C524.48 2.54 515.41-1.97 507.03.84L389.11 40.37C375.3 16.36 349.69 0 320 0c-44.18 0-80 35.82-80 80c0 3.43.59 6.71 1.01 10.03l-128.39 43.05c-8.38 2.81-12.89 11.88-10.08 20.26l10.17 30.34c2.81 8.38 11.88 12.89 20.26 10.08l142.05-47.63c4.07 2.77 8.43 5.12 12.99 7.12V496c0 8.84 7.16 16 16 16h224c8.84 0 16-7.16 16-16v-32c-.01-8.84-7.17-16-16.01-16m111.98-144c0-16.18 1.34-8.73-85.05-181.51c-17.65-35.29-68.19-35.36-85.87 0c-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80zM440 288l72-144l72 144zm-269.07-37.51c-17.65-35.29-68.19-35.36-85.87 0C-2.06 424.75.02 416.33.02 432H0c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02c0-16.18 1.34-8.73-85.05-181.51M56 416l72-144l72 144z" /></svg>
                  <div className='media-body'>
                    <h5>Remote Work Opportunitie</h5>
                    <p>Flexibility to work from anywhere anytime </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='it1' id='it5'>
                <div className='media' id='it-bg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 15 15"><path fill="#fcd62d" d="M5.5 0a3.499 3.499 0 1 0 0 6.996A3.499 3.499 0 1 0 5.5 0m-2 8.994a3.5 3.5 0 0 0-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 0 0-3.5-3.5zm9 1.006H12v5h3v-2.5a2.5 2.5 0 0 0-2.5-2.5" /><path fill="#fcd62d" d="M11.5 4a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5" /></svg>
                  <div className='media-body'>
                    <h5>Global Relevance</h5>
                    <p>IT skills transcend the international borders</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='it1' id='it6'>
                <div className='media' id='it-bg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24"><path fill="#7388de" d="M3.005 3.003h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-18a1 1 0 0 1-1-1v-16a1 1 0 0 1 1-1m1 2v14h16v-14zm4.5 9h5.5a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1v-2h2v2h2.5v2h-5.5a.5.5 0 0 0 0 1h4a2.5 2.5 0 0 1 0 5h-1v2h-2v-2h-2.5z" /></svg>
                  <div className='media-body'>
                    <h5>Future-proof</h5>
                    <p>IT innovation is here to stay forever</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='training-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-7'>
              <div className='tf-text'>
                <h2>Confused About Which Course Is <br /> Best For You?</h2>
                <h5>Let our Career Counselor help!</h5>
                  <h5>Mail Us : <a href='#'> info@vermawebsolutions.com</a></h5>
              </div>
            </div>
            <div className='col-lg-4 col-md-5'>
              <div className='tf-btn'>
                  <a href='tel:+91 6280833855'><svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" className='mr-2' id='f-phone' viewBox="0 0 24 24"><path fill="black" d="M16.5 11.456q-.31 0-.54-.23t-.23-.54t.23-.539t.54-.23t.54.23t.23.54t-.23.539t-.54.23M14 8.813l-.588-.626q.667-.552 1.454-.873q.788-.32 1.634-.32t1.634.32t1.454.873L19 8.813q-.538-.442-1.176-.688T16.5 7.879t-1.324.246T14 8.813m-2.1-2.15l-.627-.626q1.067-.99 2.415-1.517q1.347-.526 2.812-.526t2.813.526t2.414 1.517l-.627.626q-.944-.867-2.132-1.325t-2.468-.46t-2.465.462T11.9 6.663M18.93 20q-2.528 0-5.184-1.266t-4.944-3.555q-2.27-2.289-3.536-4.935T4 5.07q0-.45.3-.76T5.05 4h2.473q.408 0 .712.257t.411.658L9.142 7.3q.07.42-.025.733q-.094.313-.332.513L6.59 10.592q.616 1.118 1.361 2.076q.745.959 1.59 1.817q.87.87 1.874 1.62q1.004.749 2.204 1.414l2.139-2.177q.244-.263.549-.347t.674-.033l2.104.43q.407.1.661.41q.254.311.254.713v2.435q0 .45-.31.75t-.76.3M6.122 9.654l1.92-1.766q.096-.076.124-.211t-.01-.25l-.443-2.12q-.039-.153-.135-.23T7.327 5H5.275q-.115 0-.192.077t-.077.192q.029 1.025.32 2.14t.795 2.245m8.45 8.334q1.014.502 2.16.743t2 .257q.115 0 .192-.076t.077-.193v-2.007q0-.154-.077-.25t-.23-.135l-1.85-.379q-.116-.038-.203-.01t-.182.125zm0 0" /></svg>
                  Book a free career counselling</a>
              </div>
            </div>
          </div>
        </div>
        </section>
      </motion.div>
    </>
  )
}
