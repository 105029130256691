// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { styled, css } from '@mui/system';
// import { Modal as BaseModal } from '@mui/base/Modal';
// import Fade from '@mui/material/Fade';
// import { Button } from '@mui/base/Button';
// import './Modal.css'
// import Popupform from './Popupform';


// export default function TransitionsModal() {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       <TriggerButton onClick={handleOpen}>Book Demo</TriggerButton>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         slots={{ backdrop: StyledBackdrop }}
//       >
//         <Fade in={open}>
//           <ModalContent sx={style}>
//             <Popupform/>
//           </ModalContent>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }

// const Backdrop = React.forwardRef((props, ref) => {
//   const { open, ...other } = props;
//   return (
//     <Fade in={open}>
//       <div ref={ref} {...other} />
//     </Fade>
//   );
// });

// Backdrop.propTypes = {
//   open: PropTypes.bool,
// };

// const blue = {
//   200: '#99CCFF',
//   300: '#66B2FF',
//   400: '#3399FF',
//   500: '#007FFF',
//   600: '#0072E5',
//   700: '#0066CC',
// };

// const grey = {
//   50: '#F3F6F9',
//   100: '#E5EAF2',
//   200: '#DAE2ED',
//   300: '#C7D0DD',
//   400: '#B0B8C4',
//   500: '#9DA8B7',
//   600: '#6B7A90',
//   700: '#434D5B',
//   800: '#303740',
//   900: '#1C2025',
// };

// const Modal = styled(BaseModal)`
//   position: fixed;
//   z-index: 1300;
//   inset: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const StyledBackdrop = styled(Backdrop)`
//   z-index: -1;
//   position: fixed;
//   inset: 0;
//   background-color: rgb(0 0 0 / 0.5);
//   -webkit-tap-highlight-color: transparent;
// `;

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 450,
// };

// const ModalContent = styled('div')(
//   ({ theme }) => css`
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-weight: 500;
//     text-align: start;
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     gap: 8px;
//     overflow: hidden;
//     background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border-radius: 8px;
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     box-shadow: 0 4px 12px
//       ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
//     padding: 24px;
//     color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

//     & .modal-title {
//       margin: 0;
//       line-height: 1.5rem;
//       margin-bottom: 8px;
//     }

//     & .modal-description {
//       margin: 0;
//       line-height: 1.5rem;
//       font-weight: 400;
//       color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
//       margin-bottom: 4px;
//     }
//   `,
// );

// const TriggerButton = styled(Button)(
//   ({ theme }) => css`
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-weight: 600;
//     font-size: 0.875rem;
//     line-height: 1.5;
//     padding: 8px 16px;
//     border-radius: 8px;
//     transition: all 150ms ease;
//     cursor: pointer;
//     background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
//     box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

//     &:hover {
//       background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
//       border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
//     }

//     &:active {
//       background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
//     }

//     &:focus-visible {
//       box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
//       outline: none;
//     }
//   `,
// );

// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { styled, css } from '@mui/system';
// import { Modal as BaseModal } from '@mui/base/Modal';
// import Fade from '@mui/material/Fade';
// import { Button } from '@mui/base/Button';
// import './Modal.css';
// import Popupform from './Popupform';

// export default function TransitionsModal() {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       <TriggerButton onClick={handleOpen}>Book Demo</TriggerButton>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         slots={{ backdrop: StyledBackdrop }}
//       >
//         <Fade in={open}>
//           <ModalContent sx={style}>
//             <CloseButton onClick={handleClose}>X</CloseButton>
//             <Popupform />
//           </ModalContent>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }

// const Backdrop = React.forwardRef((props, ref) => {
//   const { open, ...other } = props;
//   return (
//     <Fade in={open}>
//       <div ref={ref} {...other} />
//     </Fade>
//   );
// });

// Backdrop.propTypes = {
//   open: PropTypes.bool,
// };

// const blue = {
//   200: '#99CCFF',
//   300: '#66B2FF',
//   400: '#3399FF',
//   500: '#007FFF',
//   600: '#0072E5',
//   700: '#0066CC',
// };

// const grey = {
//   50: '#F3F6F9',
//   100: '#E5EAF2',
//   200: '#DAE2ED',
//   300: '#C7D0DD',
//   400: '#B0B8C4',
//   500: '#9DA8B7',
//   600: '#6B7A90',
//   700: '#434D5B',
//   800: '#303740',
//   900: '#1C2025',
// };

// const Modal = styled(BaseModal)`
//   position: fixed;
//   z-index: 1300;
//   inset: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const StyledBackdrop = styled(Backdrop)`
//   z-index: -1;
//   position: fixed;
//   inset: 0;
//   background-color: rgb(0 0 0 / 0.5);
//   -webkit-tap-highlight-color: transparent;
// `;

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 450,
// };

// const ModalContent = styled('div')(
//   ({ theme }) => css`
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-weight: 500;
//     text-align: start;
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     gap: 8px;
//     overflow: hidden;
//     background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border-radius: 8px;
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     box-shadow: 0 4px 12px
//       ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
//     padding: 24px;
//     color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

//     & .modal-title {
//       margin: 0;
//       line-height: 1.5rem;
//       margin-bottom: 8px;
//     }

//     & .modal-description {
//       margin: 0;
//       line-height: 1.5rem;
//       font-weight: 400;
//       color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
//       margin-bottom: 4px;
//     }
//   `,
// );

// const TriggerButton = styled(Button)(
//   ({ theme }) => css`
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-weight: 600;
//     font-size: 0.875rem;
//     line-height: 1.5;
//     padding: 8px 16px;
//     border-radius: 8px;
//     transition: all 150ms ease;
//     cursor: pointer;
//     background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
//     box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

//     &:hover {
//       background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
//       border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
//     }

//     &:active {
//       background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
//     }

//     &:focus-visible {
//       box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
//       outline: none;
//     }
//   `,
// );

// const CloseButton = styled(Button)(
//   ({ theme }) => css`
//     align-self: flex-end;
//     font-size: 0.875rem;
//     line-height: 1.5;
//     padding: 4px 8px;
//     border-radius: 4px;
//     transition: all 150ms ease;
//     cursor: pointer;
//     background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//     border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//     color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
//     box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

//     &:hover {
//       background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
//       border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
//     }

//     &:active {
//       background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
//     }

//     &:focus-visible {
//       box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
//       outline: none;
//     }
//   `,
// );

import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import PropTypes from 'prop-types';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/base/Button';

export default function TransitionsModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <TriggerButton onClick={handleOpen}>Book Demo</TriggerButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <Popupform onClose={handleClose} />
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled(Button)(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);

const CloseButton = styled(Button)(
  ({ theme }) => css`
    align-self: flex-end;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);

function Popupform({ onClose }) {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_i53x4hl', 'template_2huh1uj', form.current, {
        publicKey: 'DIvHhnCZFxR0GiaUk',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitted(true);
          setTimeout(() => {
            setSubmitted(false);
            onClose(); // Close the modal after submission
          }, 2000); // hide success message after 1 second
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="contact-form2">
      <div className='each'>
        <h5>Enroll For Our Free Masterclasses.</h5>
        <CloseButton onClick={onClose}>X</CloseButton>
        </div>
        {submitted && (
          <div className="alert alert-success">Your message has been sent successfully!</div>
        )}
        <form ref={form} onSubmit={sendEmail} name="from_name">
          <input
            type="text"
            placeholder="Name"
            name="user_name"
            className="input1 mr-3"
            size={30}
            required
          />
          <br />
          <input
            type="Email"
            placeholder="Email"
            name="user_email"
            className="input1"
            size={30}
            required
          />
          <br />
          <input
            type="text"
            placeholder="Phone Number"
            name="user_phone"
            className="input2"
            size={60}
            required
          />
          <br />
          <input
            type="text"
            placeholder="Course*"
            name="message"
            className="input3"
            size={60}
            required
          />
          <br />
          <input
            type="submit"
            value="Submit"
            className="btn contact-btn"
            id="contact-btn"
            required
          />
        </form>
      </div>
    </>
  );
}



