import React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Form() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_i53x4hl', 'template_2huh1uj', form.current, {
          publicKey: 'DIvHhnCZFxR0GiaUk',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
          e.target.reset()
        );
    };
  return (
    <>
        <section className='contact-us'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='contact-form'>
                <h2>Reach Out for Expert Advice</h2>
                <p>Reach out to us for expert advice and assistance. Our team is ready to help with any inquiries. Contact us via email, phone, or our online form today. We’re here for you!</p>
                <form ref={form} onSubmit={sendEmail} name='from_name'>
                  <input type='text' placeholder='Name' name='user_name' className='input1 mr-3' size={30} required />
                  <input type='Email' placeholder='Email' name='user_email' className='input1' size={30} required />
                  <br />
                  <input type='text' placeholder='Phone Number' name='user_phone' className='input2' size={60} required />
                  <br />
                  <textarea typeof='text' placeholder='Please describe what you need. *' name='message' className='input3' size60 required />
                  <br />
                  <input type="submit" value="Send" className='contact-btn' id='contact-btn' required/>
                  {/* <button className='btn contact-btn'>Get a free consultation</button> */}
                </form>
              </div>
            </div>
            <div className='col-lg-6 d-md-none d-lg-block'>
              <div className='contact-map'>
                <img src='images/1th.svg' />
                {/* <nav className='map1'>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Address</a>
                    <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Google Map</a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className='media'>
                      <ul className='p-0'>
                        <li className='list-unstyled location'><LocationOnIcon id="location" /></li>
                      </ul>
                      <div className='media-body'>
                        <p>Our Location</p>
                        <span>2nd floor, Jain Electronics, Gaushala Road, Abohar</span>
                      </div>
                    </div>
                    <div className='media'>
                      <ul className='p-0'>
                        <li className='list-unstyled location'><DraftsIcon id="location" /></li>
                      </ul>
                      <div className='media-body'>
                        <p>SEND US MAIL</p>
                        <span><a href=''>vermawebsoluitions@gmail.com</a></span>
                      </div>
                    </div>
                    <div className='media'>
                      <ul className='p-0'>
                        <li className='list-unstyled location'><PhoneEnabledIcon id="location" /></li>
                      </ul>
                      <div className='media-body'>
                        <p>CALL US</p>
                        <span><a href=''>+91 62808-33855, +91 81948-33515</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div className='map'>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5467.198815117016!2d74.19204560214347!3d30.13735983508972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3917a7969f81fc83%3A0x7150ae35785a2cb8!2sVerma%20Technologies!5e0!3m2!1sen!2sin!4v1710756785975!5m2!1sen!2sin" width="600" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
