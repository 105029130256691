import React from 'react'
import Form from './Form'
import { useState, useEffect } from 'react';
import Loader from './Loader2';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact - Verma Websoluitions</title>
        <meta
          name='description'
          content="Get in touch with Verma Websolution today! Whether you have questions about our services, need technical support, or want to discuss a potential project, our team is here to help. Contact us now to start the conversation and discover how we can assist you with all your IT needs."
        />
      </Helmet>
      <motion.div
        initial={{ Y: -300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ Y: -300, opacity: 0 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
      {/* <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
          </div>
        )}
      </div> */}
      <section className='contact-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='contact-head'>Contact Us</h2>
              <a href=''><Link exact className="active_class color" to="/">Home</Link></a>
              <span>/ Contact</span>
            </div>
          </div>
        </div>
      </section>
      <section className='getin-touch'>
        <div className='container'>
          <div className='process-heading'>
            <h4>GET IN TOUCH</h4>
            <h2>Get in touch with us.</h2>
          </div>
          <div className='row' id='row'>
            <div className='col-lg-4'>
              <div className='touch1'>
                <img src='images/contact-location.png' alt='' className='jump-img' />
                <h3>Office Location</h3>
                <p>2nd floor, Jain Electronics, Gaushala Road</p>
                <p>Abohar, Punjab</p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='touch1'>
                <img src='images/phone-call.png' alt='' className='jump-img' />
                <h3>Call Us</h3>
                <p><a href=''>+91 62808-33855 </a></p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='touch1'>
                <img src='images/contact-email.png' alt='' className='jump-img' />
                <h3>Email Us</h3>
                  <p><a href=''> info@vermawebsolutions.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
        <Form />
        </motion.div>
    </>
  )
}
