import React from 'react'
import { useState, useEffect } from 'react';
import Loader from './Loader2';
import { Helmet } from 'react-helmet';
import ProjectsPagination from './Pages';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Portfolio() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <Helmet>
        <title>Portfolio - Verma Websoluitions</title>
        <meta
          name='description'
          content="Explore what our clients have to say about Verma Websolution! Read testimonials from satisfied customers who have experienced our top-notch IT services firsthand. Discover how we've helped businesses like yours achieve their goals with our innovative solutions."
        />
      </Helmet>
      {/* <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
          </div>
        )}
      </div> */}
      <motion.div
        initial={{ Y: -300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ Y: -300, opacity: 0 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
      <section className='port-banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='port-head'>Our Portfolio</h2>
              <a href=''><Link exact className="active_class color" to="/">Home</Link></a>
              <span>/ Portfolio</span>
            </div>
          </div>
        </div>
      </section>
      <section className='services'>
        <div className='container'>
          <div className='services-heading'>
            <h5>// Our Projects</h5>
            <h2>A Look at Our Projects</h2>
          </div>
          <div className='row'>
            <div className='tabs'>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Wordpress</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Figma</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false">React Js</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="mobile-tab" data-toggle="tab" href="#mobile" role="tab" aria-controls="settings" aria-selected="false">UI UX Design</a>
                </li>
              </ul>
            </div>


            <div class="tab-content">
              <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <section className='all'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/29next.png' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://www.29next.com/'>DTC Ecommerce Profitably</a></h5>
                                <p>
                                  <a href=''>Design</a><span>/</span>
                                  <a href=''>Ideas</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/intell.png' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://www.intellistocks.com/'>Global Stock Advisory</a></h5>
                                <p>
                                  <a href=''>Development</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/image-2-c.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://ellyhsieh.com/'>Product Designing</a></h5>
                                <p>
                                  <a href=''>Ideas</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/image-1-co.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://www.tella.tv/'>Make videos that make money</a></h5>
                                <p>
                                  <a href=''>Ideas</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/image-1-co2.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://hyblockcapital.com/'>Live Charts with custom indicators</a></h5>
                                <p>
                                  <a href=''>Development</a><span>/</span>
                                  <a href=''>Ideas</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/villiam.png' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://villiam-landingpage.webflow.io/'>Empowerment when investing - free of charge.</a></h5>
                                <p>
                                  <a href=''>Development</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/honey.png' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://honey-whale.webflow.io/'>We build valuable user experiences.</a></h5>
                                <p>
                                  <a href=''>Development</a><span>/</span>
                                  <a href=''>Development</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/spur.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://www.spur.fit/'>World's first Copilot for fitness trainers</a></h5>
                                <p>
                                  <a href=''>Design</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 d-md-none d-lg-block'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/beetel.png' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='https://www.beetlebeetle.com/'>SaaS businesses solutions</a></h5>
                                <p>
                                  <a href=''>Design</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                {/* <section className='all'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6'>
                        <a href='https://thisseason.dk/'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/project7.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Basics Project</h5>
                                <p>
                                  <a href=''>Development</a><span>/</span>
                                  <a href=''>Development</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                          </div>
                        </a>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <a href='https://thisseason.dk/'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/project8.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Social Media App</h5>
                                <p>
                                  <a href=''>Design</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                          </div>
                        </a>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <a href='https://thisseason.dk/'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/project9.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Your New Reality</h5>
                                <p>
                                  <a href=''>Design</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                          </div>
                          </a>
                      </div>
                    </div>
                  </div>
                </section> */}
                <ProjectsPagination/>
              </div>
              <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                <section className='all'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/sketboard.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>A different breed of skateboard</h5>
                                <p>
                                  <a href=''>Design</a><span>/</span>
                                  <a href=''>Ideas</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/featured1.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Discover how to effortlessly achieve ISO Certification</h5>
                                <p>
                                  <a href=''>Design</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/baby.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Hoping to Adopt a Baby?</h5>
                                <p>
                                  <a href=''>Ideas</a><span>/</span>
                                  <a href=''>Design</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                <section className='all'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <a href='https://www.theincircle.com/'>
                            <img src='images/image-0.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Our Vision and Mission</h5>
                                <p>
                                  <a href=''>Ideas</a><span>/</span>
                                  <a href=''>Technology</a><span></span>
                                </p>
                              </div>
                            </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <a href='https://hyblockcapital.com/'>
                            <img src='images/image-1.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Live Charts with custom indicators</h5>
                                <p>
                                  <a href=''>Development</a><span>/</span>
                                  <a href=''>Ideas</a><span></span>
                                </p>
                              </div>
                              </div>
                              </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/FEATURED.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5>Unlock the power of next-generation SD-WAN solutions</h5>
                                <p>
                                  <a href=''>Development</a><span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="tab-pane" id="mobile" role="tabpanel" aria-labelledby="settings-tab">
                <section className='all'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/selena.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='#'>Spotify Clone</a></h5>
                                <p>
                                  <a href='#'>Design</a>                          
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/Restaurant.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href=''>SERVING WITH LOVE</a></h5>
                                <p>
                                  <a href=''>Design</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div className='design1'>
                          <div className='project-img'>
                            <img src='images/Admit.jpg' alt='' className=' img-fluid' />
                            <div className='project-info'>
                              <div className='project-inner'>
                                <h5><a href='#'>Study abroad with Expert guidance from</a></h5>
                                <p>
                                  <a href='#'>Design</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        </section>
        </motion.div>
    </>
  )
}
