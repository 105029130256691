import React from 'react';
import 'animate.css';
import portfolio2 from './portfolio2';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DraftsIcon from '@mui/icons-material/Drafts';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PhoneIcon from '@mui/icons-material/Phone';
import AboutSection from './Counter';
import SimpleSlider from './Testimonial';
import { useState, useEffect } from 'react';
import Loader from './Loader2';
import Form from './Form';
import Modal from './Pop';
import CustomModal from './Pop';
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { Link } from 'react-router-dom';

export default function Home() {

  // const x = useMotionValue(200);
  // const y = useMotionValue(200);

  // const rotateX = useTransform(y, [0, 400], [45, -45]);
  // const rotateY = useTransform(x, [0, 400], [-45, 45]);

  // function handleMouse(event) {
  //   const rect = event.currentTarget.getBoundingClientRect();

  //   x.set(event.clientX - rect.left);
  //   y.set(event.clientY - rect.top);
  // }

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
          </div>
        )}
      </div>
      <section className='banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-md-6'>
              <div className='banner-text'>
                <motion.h3
                  initial={{ y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 3 }}
                  className='bann'>Creative Web & Digital Agency</motion.h3>
                <motion.h1
                  initial={{ y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 3.2 }}
                  className=''>Verma<span> WebSolutions </span>
                  Excellence</motion.h1>
                <motion.p
                  initial={{ y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 3.4 }}
                  className='animate__animated animate__fadeInUp animate__delay-2s'>We’re a company that creates websites, develops them, and helps promote them online. We’re known for our creative and effective approach to web solutions.</motion.p>
                <motion.button
                  initial={{ y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, delay: 3.6 }}
                  className='btn'>
                  <Link exact className="active_class" to="/Contact">
                    Contact Us
                  </Link>
                </motion.button>
              </div>
            </div>
            <div className='col-lg-7 col-md-6'>
              <div className='img-box'>
                <img src="images/ab.gif" class="d-block  pic-one animate__animated animate__zoomIn animate__delay-1s" alt="..." />
                <img src="images/Man.png" class="d-block  pic-two animate__animated animate__fadeInLeft animate__delay-4s" alt="..." />
                <img src="images/pic-3.png" class="d-block  pic-three animate__animated animate__fadeInUp animate__delay-3s" alt="..." />
                <img src="images/Terget.png" class="d-block  pic-four animate__animated animate__fadeInDown animate__delay-3s" alt="..." />
                <img src="images/Women.png" class="d-block  pic-five animate__animated animate__fadeInDown animate__delay-4s" alt="..." />
                <img src="images/pic-6.png" class="d-block  pic-six animate__animated animate__jackInTheBox animate__delay-5s" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='our-Offerings'>
        <div className='container'>
          <div className='offerings-heading'>
            <h2>Our <span>Offerings</span></h2>
            <p>Our team of skilled professionals combines creativity with technical expertise to produce captivating websites that captivate audiences and drive engagement. With a customer-centric approach at our core, we prioritize understanding our clients’ goals and objectives to deliver customized solutions that exceed expectations.</p>
          </div>
          <div className='row'>
            <div className="col-lg-4 col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                whileHover={{ scale: 1.02, boxShadow: "0px 5px 8px #FD660A" }}
                className="s-1">
                <div className="media">
                  <img src="images/uicolored.png" alt="kapil" className="main-img img-fluid"></img>
                  <img src="images/uiwhite.png" alt="kapil" className="sec-img img-fluid"></img>
                  <div className="media-body" id="m-text">
                    <h4>01</h4>
                  </div>
                </div>
                <div className="s-text ">
                  <a href="">UI / UX Design</a>
                  <p>We want our website to look nice and be interesting for people who visit it. Our team of talented experts works hard to create special designs for the different parts of the website.</p>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-4 col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                whileHover={{ scale: 1.02, boxShadow: "0px 5px 8px #F30876" }}
                className="s-1" id="s-1">
                <div className="media">
                  <img src="images/webscolor.png" alt="kapil" className="main-img img-fluid"></img>
                  <img src="images/webswhite.png" alt="kapil" className="sec-img img-fluid"></img>
                  <div className="media-body" id="m-text">
                    <h4>02</h4>
                  </div>
                </div>
                <div className="s-text ">
                  <a href="">Website Design</a>
                  <p>We’re here to ensure your website leaves a lasting impression, as it’s like your brand’s face on the internet.</p>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-4 col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                whileHover={{ scale: 1.02, boxShadow: "0px 5px 8px #05DBEE" }}
                className="s-1" id="s-2">
                <div className="media">
                  <img src="images/shopifycolor.png" alt="kapil" className="main-img img-fluid"></img>
                  <img src="images/shopifywhite.png" alt="kapil" className="sec-img img-fluid"></img>
                  <div className="media-body" id="m-text">
                    <h4>03</h4>
                  </div>
                </div>
                <div className="s-text ">
                  <a href="">Shopify Development</a>
                  <p>We make your Shopify store special by adding unique features like cool product pages that change, making checkout personal for each shopper.</p>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-4  col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                whileHover={{ scale: 1.02, boxShadow: "0px 5px 8px #13E1AF" }}
                className="s-1" id="s-3">
                <div className="media">
                  <img src="images/graphic-color.png" alt="kapil" className="main-img img-fluid"></img>
                  <img src="images/graphic-white.png" alt="kapil" className="sec-img img-fluid"></img>
                  <div className="media-body" id="m-text">
                    <h4>04</h4>
                  </div>
                </div>
                <div className="s-text ">
                  <a href="">Grapic Design</a>
                  <p>Aiviq Technologies excels in Chandigarh as the premier Graphic Designing Company, crafting visually appealing and user-friendly websites.</p>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-4 col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                whileHover={{ scale: 1.02, boxShadow: "0px 5px 8px #780FDA" }}
                className="s-1" id="s-4">
                <div className="media">
                  <img src="images/speed-color.png" alt="kapil" className="main-img img-fluid"></img>
                  <img src="images/speed-white.png" alt="kapil" className="sec-img img-fluid"></img>
                  <div className="media-body" id="m-text">
                    <h4>05</h4>
                  </div>
                </div>
                <div className="s-text ">
                  <a href="">Website Speed Optimisation</a>
                  <p>Fast websites lead to more conversions. Rely on us for optimizing your website speed to boost performance.</p>
                </div>
              </motion.div>
            </div>
            <div className="col-lg-4 col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.1 }}
                whileHover={{ scale: 1.02, boxShadow: "0px 5px 8px #0A99FD" }}
                className="s-1" id="s-5">
                <div className="media">
                  <img src="images/mac-color.png" alt="kapil" className="main-img img-fluid"></img>
                  <img src="images/mac-white.png" alt="kapil" className="sec-img img-fluid"></img>
                  <div className="media-body" id="m-text">
                    <h4>06</h4>
                  </div>
                </div>
                <div className="s-text ">
                  <a href="">Web Design & Development</a>
                  <p>Trust our team for efficient API integration, website development, and seamless results.</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <portfolio2 />
      <AboutSection />
      <SimpleSlider />
      <section className='faq'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-md-5'>
              <div className='faq-1'>
                <h5>faq</h5>
                <h2>Most common question <br></br>about our services</h2>
                <a href="tel:+91 6280833855">GET IN TOUCH</a>
              </div>
            </div>
            <div className='col-lg-7 col-md-7'>
              <div className='faq-2'>
                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0" id='card-bn'>
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          What types of web development services do you offer?
                          <KeyboardArrowDownIcon className='icon2' />
                        </button>
                      </h5>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        "We specialize in a wide range of web development services including website development (WordPress, Shopify, PHP, JavaScript, CodeIgniter, E-Commerce, and Wix), and landing page creation using tools like Framer, Duda, and Webflow. Whether you need a complete website overhaul or a custom-built e-commerce platform, we have the expertise to meet your needs."
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How do you ensure the security of the websites you develop?
                          <KeyboardArrowDownIcon className='icon3' />
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div class="card-body">
                        "At Verma Websolutions, we prioritize the security of the websites we develop. We implement best practices for secure coding, regular updates, and thorough testing to protect against vulnerabilities. Additionally, we offer ongoing maintenance and security monitoring services to ensure your website remains safe and secure."
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Can you help with search engine optimization for my website?
                          <KeyboardArrowDownIcon className='icon4' />
                        </button>
                      </h5>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                      <div class="card-body">
                        "Yes, we offer comprehensive SEO services to improve your website’s visibility on search engines. Our team uses the latest SEO strategies, including keyword research, on-page optimization, link building, and content creation, to help your website rank higher and attract more organic traffic."
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='news'>
        <div className='container'>
          <div className='news-heading'>
            <h4>POPULAR NEWS</h4>
            <h2>Latest From our blog</h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 margin'>
              <div className='news-main'>
                <div className='news-img'>
                  <a href=''>
                    <img src='images/blog1.jpeg' className='w-100 img-fluid' />
                  </a>
                </div>
                <div className='news-text'>
                  <ul className='p-0'>
                    <li className='list-unstyled'><CalendarMonthIcon id="li-icon" />  January 15 2024</li>
                    <li className='list-unstyled'><PersonOutlineIcon id="li-icon" />  Admin</li>
                  </ul>
                  <h4><a href=''>The Future of Web Development: Trends to Watch in 2024</a></h4>
                  <p>Stay ahead of the curve with the latest trends in web development. This blog post explores emerging technologies, design philosophies, and best practices that are shaping the future of the industry. </p>
                  <a href='' className='text-a'>Read More <ArrowForwardIosIcon id='a-icon' /></a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12 col-md-6 margin'>
              <div className='news-main'>
                <div className='news-img'>
                  <a href=''>
                    <img src='images/blog2.jpeg' className='w-100 img-fluid' />
                  </a>
                </div>
                <div className='news-text'>
                  <ul className='p-0'>
                    <li className='list-unstyled'><CalendarMonthIcon id="li-icon" />  March 10 2024</li>
                    <li className='list-unstyled'><PersonOutlineIcon id="li-icon" />  Admin</li>
                  </ul>
                  <h4><a href=''>Mastering Responsive Design: Tips and Techniques for a Seamless User Experience</a></h4>
                  <p>Responsive design is crucial for delivering a consistent user experience. This post provides practical tips and techniques for creating websites that look and function perfectly on any screen size.</p>
                  <a href='' className='text-a'>Read More <ArrowForwardIosIcon id='a-icon' /></a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12 d-md-none d-lg-block margin'>
              <div className='news-main'>
                <div className='news-img'>
                  <a href=''>
                    <img src='images/blog3.jpeg' className='w-100 img-fluid' />
                  </a>
                </div>
                <div className='news-text'>
                  <ul className='p-0'>
                    <li className='list-unstyled'><CalendarMonthIcon id="li-icon" />  June 20 2024</li>
                    <li className='list-unstyled'><PersonOutlineIcon id="li-icon" />  Admin</li>
                  </ul>
                  <h4><a href=''>Boost Your Website's Performance: Essential Optimization Strategies</a></h4>
                  <p>A fast, efficient website is key to retaining visitors and improving SEO. This blog post covers essential strategies for optimizing your website's performance.Your website run faster for an enhanced user experience.</p>
                  <a href='' className='text-a'>Read More <ArrowForwardIosIcon id='a-icon' /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact-us start  */}

      <Form />
    </>
  )
}
