import React from 'react'
import { useState, useEffect } from 'react';
import Loader from './Loader2';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


export default function Services() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);
  return (
    <>
      <Helmet>
        <title>Services - Verma Websoluitions</title>
        <meta
          name='description'
          content='Explore the comprehensive range of IT services offered by Verma Web Solutions. From website development to digital marketing, we have the expertise to help your business succeed online.'
        />
      </Helmet>
      <motion.div
        initial={{ Y: -300, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ Y: -300, opacity: 0 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
      {/* <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
          </div>
        )}
      </div> */}
      <section className='services-banner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='services-head'>Our Services</h2>
              <a href=''><Link exact className="active_class color" to="/">Home</Link></a>
              <span>/ Services</span>
            </div>
          </div>
        </div>
      </section>

      <section className='service-list'>
        <div className='container'>
          <div className='service-list-head'>
            <h5>// What We Do</h5>
            <h2>Our Suite of Digital Solutions</h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/digital-services.png' alt='' className='jump2' />
                <h5>Web Development</h5>
                <p>We carry more than just good coding skills. Our experience makes us stand out from other web development.</p>
                <div className='round'>
                  <span>1</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/experiments.png' alt='' className='jump2' />
                <h5>Mobile Development</h5>
                <p>Create complex enterprise software, ensure reliable software integration, modernise your legacy system.</p>
                <div className='round'>
                  <span>2</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/ui-design.png' alt='' className='jump2' />
                <h5>UI/UX Design</h5>
                <p>Build the product you need on time with an experienced team that uses a clear and effective design process.</p>
                <div className='round'>
                  <span>3</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/qa.png' alt='' className='jump2' />
                <h5>QA & Testing</h5>
                <p>Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.</p>              <div className='round'>
                  <span>4</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/consulting.png' alt='' className='jump2' />
                <h5>IT Counsultancy</h5>
                <p>Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.</p>
                <div className='round'>
                  <span>5</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/cyber-security.png' alt='' className='jump2' />
                <h5>Managed Cyber Security</h5>
                <p>A Managed Cyber security services provider is an outside services provider we focused cybersecurity technology.</p>
                <div className='round'>
                  <span>6</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/computer.png' alt='' className='jump2' />
                <h5>Manage Application Security</h5>
                <p>Web development refers to the creating, building, and maintaining of websites.</p>
                <div className='round'>
                  <span>7</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='service-content1'>
                <img src='images/smartphone.png' alt='' className='jump2' />
                <h5>Payment Solutions</h5>
                <p>We specialize in devising customized payment solutions that are secure, error-free and ideal for your business.</p>
                <div className='round'>
                  <span>8</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 d-md-none d-lg-block'>
              <div className='service-content1'>
                <img src='images/engagement.png' alt='' className='jump2' />
                <h5>Dedicated Team</h5>
                <p>Over the past decade, our customers succeeded by leveraging Intellectsoft’s process of building, motivating.</p>
                <div className='round'>
                  <span>9</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='service-contact'>
        <div className='container'>
          <div className='service-contact-text'>
            <p>We are here to answer your questions 24/7</p>
            <h2>NEED A CONSULTATION?</h2>
              <a href='tel:+91 6280833855' className='btn service-btn'>Contact Us</a>
          </div>
        </div>
        </section>
        </motion.div>
    </>
  )
}
