import React, { useEffect, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';

const CounterSection = () => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            startCounter();
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const startCounter = () => {
    let currentCount = 0;
    const startTime = performance.now();
    const duration = 10000; // Adjust the duration as needed (in milliseconds)

    const updateCounter = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const targetCount = Math.floor(progress * 875);

      setCount(targetCount);

      if (progress < 1) {
        requestAnimationFrame(updateCounter);
      }
    };

    requestAnimationFrame(updateCounter);
  };

  return (
    <section className='about' ref={sectionRef}>
      <div className='container'>
        <div className='works-about'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='about-text'>
                <h5>WHY CHOOSE US</h5>
                <h2>Commitment, Quality <br />& Results</h2>
                <p>Verma WebSolutions three pillars: Commitment, Quality, and Results. Our dedication ensures projects are delivered with excellence, reflecting our commitment to exceeding expectations. With a focus on quality craftsmanship and attention to detail, we strive for unparalleled results, driving success for our clients.</p>
                <ul>
                  <li><CheckIcon className='icon' /><h5>100% Client Satisfaction</h5></li>
                  <li><CheckIcon className='icon' /><h5>Masters in Ui Ux Design</h5></li>
                  <a href='tel:+91 6280833855'>Talk to a consultant</a>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='about-img'>
                <img src='images/3.jpg' alt='' className='w-100 img-fluid' />
                <div className='about-img-fun'>
                  <div className='timer'>
                    {isVisible ? count : 0}
                  </div>
                  <span>Completed Projects</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
