import React, { useState } from 'react';

const projectsData = [
    {
        img: 'images/site1.png',
        title: 'Womens clothing store',
        link: 'https://thisseason.dk/',
        tags: ['Designs'],
    },
    {
        img: 'images/site2.png',
        title: 'Danny Gordon Art is an art brand',
        link: 'https://dannygordonart.com/',
        tags: ['Design', 'Technology'],
    },
    {
        img: 'images/site3.png',
        title: 'Pastori is for the mans footwear brand',
        link: 'https://pastorifootwear.com/',
        tags: ['Design', 'Technology'],
    },
    {
        img: 'images/site4.png',
        title: 'PURAVIDA store',
        link: 'https://www.puravidabracelets.com/',
        tags: ['Development', 'Development'],
    },
    {
        img: 'images/site5.png',
        title: 'America First Healthcare is a insurance agency',
        link: 'https://americafirsthealthcare.com/',
        tags: ['Design', 'Technology'],
    },
    {
        img: 'images/site0.png',
        title: 'Arrienda y encuentra financiamiento para tu maquinaria ideal',
        link: 'https://maquinatrix.netlify.app/',
        tags: ['Design', 'Technology'],
    },
    {
        img: 'images/site7.png',
        title: 'Babies store',
        link: 'https://babylama.com/',
        tags: ['Development', 'Development'],
    },
    {
        img: 'images/site8.png',
        title: 'Mobile accessories store',
        link: 'https://pluggear.com/',
        tags: ['Design', 'Development'],
    },
    {
        img: 'images/site9.png',
        title: 'Sparkling store',
        link: 'https://drinkjimmys.com/',
        tags: ['Design', 'Technology'],
    },
    {
        img: 'images/site10.png',
        title: 'FullOrbit It agency',
        link: 'https://fullorbitagency.com/',
        tags: ['Design', 'Technology'],
    },
    {
        img: 'images/site11.png',
        title: 'Best Quaslity Ac Services Provider',
        link: 'https://ac.24sevensites.com/',
        tags: ['Design', 'Services'],
    },
    {
        img: 'images/site12.png',
        title: 'Caring For Galveston County!',
        link: 'https://www.docontheblock.net/',
        tags: ['Development', 'Services'],
    },
    {
        img: 'images/site13.png',
        title: 'Bio Test Laboratory',
        link: 'https://bio-test.ca/',
        tags: ['Designs', 'Services'],
    },
    {
        img: 'images/site14.png',
        title: 'THE SHADE STORE',
        link: 'https://theshade.com.au/',
        tags: ['Development', 'Designs'],
    },
    {
        img: 'images/site15.png',
        title: 'Texi Rentals Services',
        link: 'https://taxi-rentals.com/',
        tags: ['Development', 'Services'],
    },
    {
        img: 'images/site16.png',
        title: 'STITCH Play Store',
        link: 'https://in.stitch-play.co.uk/',
        tags: ['Development', 'Services'],
    },
    {
        img: 'images/site17.png',
        title: 'Staging-rachaelattard kinsta-cloud',
        link: 'https://staging-rachaelattardcom.kinsta.cloud/',
        tags: ['Design', 'Services'],
    },
    // Add more projects here if needed
];

const ProjectsPagination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 3;
    const totalPages = Math.ceil(projectsData.length / projectsPerPage);

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    const renderProjects = () => {
        const startIndex = (currentPage - 1) * projectsPerPage;
        const endIndex = Math.min(startIndex + projectsPerPage, projectsData.length);
        return projectsData.slice(startIndex, endIndex).map((project, index) => (
            <div className='col-lg-4 col-md-6' key={index}>
                <a href={project.link} target='_blank' rel='noopener noreferrer'>
                    <div className='design1'>
                        <div className='project-img'>
                            <img src={project.img} alt='' className='img-fluid' />
                            <div className='project-info'>
                                <div className='project-inner'>
                                    <h5>{project.title}</h5>
                                    <p>
                                        {project.tags.map((tag, idx) => (
                                            <span key={idx}>
                                                <a href=''>{tag}</a>
                                                {idx < project.tags.length - 1 && <span>/</span>}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        ));
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className='page-link' onClick={() => handleClick(i)}>{i}</button>
                </li>
            );
        }
        return pages;
    };

    return (
        <section className='all'>
            <div className='container'>
                <div className='row'>
                    {renderProjects()}
                </div>
                <nav aria-label='Page navigation'>
                    <ul className='pagination justify-content-center'>
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className='page-link' onClick={() => handleClick(currentPage - 1)}>&lt;</button>
                        </li>
                        {renderPagination()}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className='page-link' onClick={() => handleClick(currentPage + 1)}>&gt;</button>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    );
};

export default ProjectsPagination;
